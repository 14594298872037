import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { useEffect, useState } from "react";
import { fetchData } from "../../components/Helper";
import Datatables, { reloadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { Helmet } from "react-helmet";
import { createRoot } from 'react-dom/client'

function CourseList(){
    const navigate = useNavigate()
    const location = useLocation();
    const listType = useParams().list_type
    // let status_q = listType ? `status_q=${listType}` : ''
    const [dt] = useState({
        dt_url: `course-list/${listType ? 'draft' : 'active'}`,
        dt_name: 'course_list',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"image", name:'image', title:"Subject Image", width:30 , className:'table-img'},
            { data:"name", name:'name', title:"Course Name", width:255},
            { data:"teacher_name", name:'users.name', title:"Teacher Name", width:161},
            { data:"description", name:'description', title:"Course Description" , width:569},
            { data:"status", name:'status',  title:"Status" },
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                        <div className="table-imgbox table-img60">
                            <img src={rowData.image} alt="weteach"/>
                        </div>
                    </>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<>
                       <a href={`/accounts/teacher/profile/${rowData.teacher_id}`} className="text-capitalize text-primary text-decoration-none">{cellData}</a>
                       </>
                    )
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.status === "draft" ? <span className="text-primary fw-medium">Draft</span> : '' }
                    </>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>

                        { rowData.status === 'inactive' ? 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleChangeStatus('active', rowData.id)}>
                                Activate
                        </button>
                        : '' }

                        { rowData.status === 'active' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleChangeStatus('inactive', rowData.id)}>
                            Deactivate
                        </button>
                        : '' }
                        
                        { rowData.status !== 'draft' ?
                        <button type="button" className="btn btn-primary py-2px m-2px" title="View" onClick={() => handleViewCourse(rowData.id)}>
                            View
                        </button>
                        : '' }

                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" onClick={() => handleEditCourse(rowData.id)}>
                            Edit
                        </button>

                        { rowData.status === 'draft' ?
                        <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={() => handleChangeStatus('delete', rowData.id)}>
                            Delete
                        </button>
                        : '' }
                        
                                                    
                    </>)
                },
            },
        ]
    })

    useEffect(() => {
        reloadUrlDataTable(dt, `course-list/${listType ? 'draft' : 'active'}`)
    }, [dt, listType])

    // useEffect(() => {
    //     initDataTable(dt)
    // }, [dt, listType])

    const handleChangeStatus = (status, id) => {
        fetchData(`course/change-status`, 'POST', {'id': id, 'status': status, 'pathname': location.pathname}, true, false, (res) => {
            reloadDataTable(dt)
        }, (err) => {})
    }
 
    
    const handleEditCourse = (id) => {
        if(listType){
            navigate(`/course/${listType}/edit/${id}`) 
        }else{
            navigate(`/course/edit/${id}`) 
        }
    }   
    const handleViewCourse = (id) => {
        navigate(`/course/details/${id}`) 
    }

    const handleChangeTab = (type) => {
        reloadUrlDataTable(dt, `course-list/${type}`)
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Courses List</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Courses List
                            </h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/course/create`} className="btn btn-blue px-3 mb-1 me-2 manage-responsive-btn" title="Create Course">Create Course</Link>
                        {
                            listType ? 
                                <Link to={`/course/list`} className="btn btn-warning px-3 mb-1 manage-responsive-btn" title="Course List"> Course List</Link>
                            :   <Link to={`/course/list/draft`} className="btn btn-warning px-3 mb-1 manage-responsive-btn" title="Course Draft">Course Draft</Link>
                        }
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    {!listType && <>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#classes-box" type="button" role="tab" data-tablename="active" title="Active" onClick={() => handleChangeTab('active')}>Active</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue  fw-medium" data-bs-toggle="tab" data-bs-target="#classes-box" type="button" role="tab" data-tablename="inactive" title="Inactive" onClick={() => handleChangeTab('inactive')}>Inactive</button>
                                        </li>
                                    </ul>
                                    </>}
                                    <div className={`tab-content border ${!listType && 'border-top-0'}`}>
                                        <div className="tab-pane fade p-3 show active" id="classes-box" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="course_list"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CourseList